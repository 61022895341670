import React, { useState, useEffect, useRef } from 'react';
import './WhackAFrog.css';

const WhackAFrog = () => {
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(120);
  const [gameStarted, setGameStarted] = useState(false);
  const [activeTarget, setActiveTarget] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [whackedTarget, setWhackedTarget] = useState(null);
  const [lastHole, setLastHole] = useState(null);

  const audioRef = useRef(null);
  const whackSoundRef = useRef(null);
  const bombSoundRef = useRef(null);

  useEffect(() => {
    const preloadImages = ['/head.png', '/headshot.png', '/bomb.png', '/hammer.png', '/whackafrog.png'];
    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });

    const preloadAudios = ['/gamebg.mp3', '/whack.mp3', '/bomb.mp3'];
    preloadAudios.forEach((src) => {
      const audio = new Audio();
      audio.src = src;
    });

    audioRef.current = new Audio('/gamebg.mp3');
    audioRef.current.loop = true;
    whackSoundRef.current = new Audio('/whack.mp3');
    bombSoundRef.current = new Audio('/bomb.mp3');

    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    if (gameStarted) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [gameStarted]);

  useEffect(() => {
    let timer;
    if (gameStarted && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setGameOver(true);
      setGameStarted(false);
    }
    return () => clearInterval(timer);
  }, [gameStarted, timeLeft]);

  useEffect(() => {
    let targetTimer;
    if (gameStarted) {
      targetTimer = setInterval(() => {
        let newHole;
        do {
          newHole = Math.floor(Math.random() * 9);
        } while (newHole === lastHole);
        
        setLastHole(newHole);
        setActiveTarget({
          index: newHole,
          isBomb: Math.random() < 0.2
        });
      }, 1000);
    }
    return () => clearInterval(targetTimer);
  }, [gameStarted, lastHole]);

  const startGame = () => {
    setScore(0);
    setTimeLeft(120);
    setGameStarted(true);
    setGameOver(false);
    setLastHole(null);
  };

  const whack = (index) => {
    if (activeTarget && index === activeTarget.index) {
      if (activeTarget.isBomb) {
        setScore((prevScore) => Math.max(prevScore - 200, 0));
        bombSoundRef.current.currentTime = 0;
        bombSoundRef.current.play();
      } else {
        setScore((prevScore) => prevScore + 200);
        setWhackedTarget(index);
        whackSoundRef.current.currentTime = 0;
        whackSoundRef.current.play();
        setTimeout(() => setWhackedTarget(null), 300);
      }
      setActiveTarget(null);
    }
  };

  return (
    <div className="whack-a-frog">
      <div className="game-container">
        <div className="game-info">
          <div className="score">SCORE: {score}</div>
          <div className="timer">TIME: {timeLeft}s</div>
        </div>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${(timeLeft / 120) * 100}%` }}></div>
        </div>
        {!gameStarted && !gameOver && (
          <>
            <img src="/whackafrog.png" alt="Whack-A-Frog" className="game-title" />
            <button className="start-button" onClick={startGame}>PLAY WHACK-A-FROG</button>
          </>
        )}
        {gameStarted && (
          <div className="game-grid">
            {[...Array(9)].map((_, index) => (
              <div key={index} className="hole" onClick={() => whack(index)}>
                {activeTarget && activeTarget.index === index && (
                  <img
                    src={activeTarget.isBomb ? "/bomb.png" : "/head.png"}
                    alt={activeTarget.isBomb ? "Bomb" : "Frog"}
                    className={`target ${whackedTarget === index ? 'whacked' : ''}`}
                  />
                )}
                {whackedTarget === index && (
                  <img
                    src="/headshot.png"
                    alt="Whacked Frog"
                    className="target whacked"
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {gameOver && (
          <div className="game-over">
            <h2>SYSTEM SHUTDOWN</h2>
            <p>FINAL SCORE: {score}</p>
            <button className="restart-button" onClick={startGame}>REBOOT SYSTEM</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhackAFrog;