import React, { useState } from 'react';
import './Story.css';

const Raids = () => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const templates = [
    `"Check out $FROG on Solana, inspired by childhood memories and a favorite book of Elon Musk. Based on a real story by Arnold Lobel, this is not Pepe but 40 years older.
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `"Yo, check out $FROG, Solana's next big Frog. Inspired by *Frog and Toad* from 1971, this is Pepe's great-grandfather and it's loved by Elon.
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `"I am happy, Toad, because I got $FROG!" 🐸
Frog is a wholeseome meme about friendship and nostalgia, with massive potential. Could be the next $1B+ runner on Solana.
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `Hey, check out Elon Musk's childhood favorite Frog on Solana! 🚀  
Frog and Toad taught us friendship and adventure—now they're pumping together.
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `"Frog, is $FROG going to the moon?"  
"Yes, Toad… and beyond!" 
$FROG is about memories, frens, and massive gains. Don't miss out.  
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `In *Frog and Toad*, every small adventure mattered. With $FROG, the community is based & friendly. 
Embrace nostalgia from the 1971 character by Arnold Lobel; a real character.
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `Inspired by the friendship in *Frog and Toad*, $FROG probably has the best website in memecoins with FROG OS. Check this out bro:
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `"Toad, what do we do with our $FROG?"  
"We HODL, Frog. We HODL." 💎
Elon Musk's favorite book. You really should check out Frog:
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `"Toad, how high will $FROG go?"  
"As high as we dare to dream, Frog." 🐸  
Join us on Solana with $FROG, a tribute to *Frog and Toad* by Arnold Lobel from 1971 and a token with big dreams.   
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`,

    `Look into $FROG from the pages of a 70s book "Frog And Toad"! 🚀  
With Elon Musk's childhood favorite as inspiration, $FROG is the next sender on Solana. Check out the website too.
CA: sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump
https://ToadAndFrog.com`
  ];

  const handleCopy = async (text, index) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="story-container" style={{ userSelect: 'text' }}>
      <div className="explorer-bar">
        C:/Frog OS/Raids.txt
      </div>
      <div className="content-wrapper">
        <div className="story-content">
        <h1 style={{ textAlign: 'center', marginBottom: '2rem', color: '#00ff00' }}>FROG Raid Templates</h1>
          {templates.map((template, index) => (
            <div key={index} style={{ marginBottom: '2rem', position: 'relative' }}>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'flex-start',
                gap: '1rem'
              }}>
                <pre style={{ 
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'inherit',
                  marginTop: '0.5rem',
                  padding: '1rem',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  flex: 1,
                  userSelect: 'text',
                  color: '#000'
                }}>
                  <strong>{index + 1}.</strong> {template}
                </pre>
                <button
                  onClick={() => handleCopy(template, index)}
                  style={{
                    background: '#4CAF50',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '0.5rem 1rem',
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: '14px',
                    height: '40px',
                    marginTop: '0.5rem',
                    transition: 'all 0.2s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.background = '#45a049';
                    e.target.style.transform = 'translateY(-1px)';
                    e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.background = '#4CAF50';
                    e.target.style.transform = 'translateY(0)';
                    e.target.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
                  }}
                  title="Copy to clipboard"
                >
                  {copiedIndex === index ? '✓ Copied' : 'Copy'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Raids;