import React, { useState, useRef, useEffect } from 'react';
import './Player.css';
import listenerIcon from './listener.png';

const Player = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [wasPlayingBeforeHidden, setWasPlayingBeforeHidden] = useState(false);

  const songs = [
    { title: "Brother Louie", artist: "Modern Talking", file: "brotherlouie.mp3" },
    { title: "Money, Money, Money", artist: "ABBA", file: "money.mp3" },
    { title: "Cheri Cheri Lady", artist: "Modern Talking", file: "cheri.mp3" },
    { title: "Take On Me", artist: "a-ha", file: "takeonme.mp3" },
    { title: "Eye Of The Tiger", artist: "Survivor", file: "eyeofthetiger.mp3" },
    { title: "The Final Countdown", artist: "Europe", file: "final.mp3" },
  ];

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));

    // Add visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', () => setDuration(audio.duration));
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // Page is hidden
      setWasPlayingBeforeHidden(isPlaying);
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    } else {
      // Page is visible again
      if (wasPlayingBeforeHidden) {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  const updateProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const playSong = (index) => {
    setCurrentSongIndex(index);
    setIsPlaying(true);
    if (audioRef.current) {
      audioRef.current.src = songs[index].file;
      audioRef.current.play();
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else if (currentSongIndex !== null) {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const stop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleProgressChange = (e) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    audioRef.current.currentTime = time;
  };

  return (
    <div className="player-container">
      <div className="player-header">
        <img src={listenerIcon} alt="Listener Icon" className="player-logo" />
        <h2>Frog MP3 Player</h2>
      </div>
      <div className="player-controls">
        <button onClick={togglePlay} className="control-button play-pause">
          {isPlaying ? '❚❚' : '▶'}
        </button>
        <button onClick={stop} className="control-button stop">
          ■
        </button>
        <div className="volume-control">
          <span className="volume-icon">🔊</span>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
        </div>
      </div>
      <div className="progress-container">
        <input
          type="range"
          min="0"
          max={duration}
          value={currentTime}
          onChange={handleProgressChange}
          className="progress-bar"
        />
        <div className="time-display">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      <div className="song-list">
        {songs.map((song, index) => (
          <div
            key={index}
            className={`song-item ${currentSongIndex === index && isPlaying ? 'active' : ''}`}
            onClick={() => playSong(index)}
          >
            <span className="song-number">{index + 1}</span>
            <div className="song-info">
              <span className="song-title">{song.title}</span>
              <span className="song-artist">{song.artist}</span>
            </div>
          </div>
        ))}
      </div>
      <audio ref={audioRef} onEnded={() => setIsPlaying(false)} />
    </div>
  );
};

export default Player;