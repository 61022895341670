import React, { useState } from 'react';
import './Explorer.css';

const Explorer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const defaultUrl = 'https://en.wikipedia.org/wiki/Frog_and_Toad';

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="explorer">
      <div className="explorer-toolbar">
        <div className="static-url">{defaultUrl}</div>
      </div>
      <div className="explorer-content">
        {isLoading && (
          <div className="loading-indicator">
            <div className="spinner"></div>
            <span>Loading...</span>
          </div>
        )}
        <iframe
          src={defaultUrl}
          title="Explorer"
          className="explorer-iframe"
          onLoad={handleIframeLoad}
          sandbox="allow-scripts allow-same-origin allow-forms"
        />
      </div>
    </div>
  );
};

export default Explorer;