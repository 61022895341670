import React, { useState } from 'react';
import './Travel.css';

const Travel = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { id: 1, src: "/asia.png", name: "Asia" },
    { id: 2, src: "/losangeles.png", name: "Los Angeles" },
    { id: 3, src: "/newyork.png", name: "New York" },
    { id: 4, src: "/athome.png", name: "At Home" },
    { id: 5, src: "/space.png", name: "In Space" },
    { id: 6, src: "/paris.png", name: "Paris" }
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleBack = () => {
    setSelectedImage(null);
  };

  const handleDownload = (imageSrc, imageName) => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="travel-container">
      <div className="explorer-bar">
        C:/Hops OS/Gallery
      </div>
      {selectedImage ? (
        <div className="image-viewer">
          <button onClick={handleBack} className="back-button">Back</button>
          <a 
            href="#" 
            onClick={(e) => {
              e.preventDefault();
              handleDownload(selectedImage.src, selectedImage.name);
            }} 
            className="download-link"
          >
            Download
          </a>
          <img src={selectedImage.src} alt={selectedImage.name} />
        </div>
      ) : (
        <>
          <p className="travel-intro">
            Explore Hops' travels around the world!
          </p>
          <div className="image-grid">
            {images.map((image) => (
              <div key={image.id} className="image-item" onClick={() => handleImageClick(image)}>
                <img src={image.src} alt={image.name} />
                <span>{image.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Travel;