import React, { useState } from 'react';
import './HopsCmd.css';

const HopsCmd = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const commands = {
    '/story': 'Elon Musk followed a frog called Toad on Twitter, inspired by an original cartoon series from 1971 called "Frog and Toad". The token turned out to be a scam, but Toad mooned. This coin is the frog from the series Frog And Toad.',
    '/twitter': 'The Frog Twitter is https://twitter.com/FrogAndToadCoin',
    '/telegram': 'The Frog Telegram is https://t.me/FrogByLobel',
    '/ca': 'sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump',
    '/supply': 'The supply of Frog is 1 billion tokens.',
    '/tax': 'Frog has 0% tax.',
    '/lp': 'The Frog LP is secured by Pump Fun.'
  };

  const availableCommands = 'Available commands: /story /ca /twitter /telegram /supply /lp /tax';

  const initialMessage = `Welcome to Frog Command Prompt. Type a command to begin.\n\n${availableCommands}`;

  useState(() => {
    setOutput(initialMessage);
  });

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const command = input.trim().toLowerCase();
    if (commands[command]) {
      setOutput(`${commands[command]}\n\n${availableCommands}`);
    } else {
      setOutput(`Error: Command not recognized. Please try again.\n\n${availableCommands}`);
    }
    setInput('');
  };

  return (
    <div className="hops-cmd-container">
      <div className="hops-cmd-output">
        <pre>{output}</pre>
      </div>
      <form onSubmit={handleSubmit} className="hops-cmd-input">
        <span>&gt;</span>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Enter a command..."
          autoFocus
        />
      </form>
    </div>
  );
};

export default HopsCmd;