import React, { useState, useRef, useEffect } from 'react';
import './Paint.css';

const Paint = () => {
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [tool, setTool] = useState('pencil');
  const [color, setColor] = useState('#00FF00');
  const [size, setSize] = useState(1);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const context = canvas.getContext('2d');
    context.fillStyle = '#000000';
    context.fillRect(0, 0, canvas.width, canvas.height);
    setCtx(context);
  }, []);

  const startDrawing = (e) => {
    if (tool === 'bucket') {
      handleBucketFill(e);
    } else {
      ctx.beginPath();
      ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      setDrawing(true);
    }
  };

  const draw = (e) => {
    if (!drawing) return;
    ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctx.stroke();
  };

  const endDrawing = () => {
    ctx.closePath();
    setDrawing(false);
  };

  const handleToolChange = (newTool) => {
    setTool(newTool);
    if (newTool === 'eraser') {
      ctx.strokeStyle = '#000000';
    } else {
      ctx.strokeStyle = color;
    }
    updateBrushSize();
  };

  const handleColorChange = (newColor) => {
    setColor(newColor);
    if (tool !== 'eraser') {
      ctx.strokeStyle = newColor;
    }
  };

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    updateBrushSize();
  };

  const updateBrushSize = () => {
    if (ctx) {
      ctx.lineWidth = size * 2;
      ctx.lineCap = 'square';
      ctx.lineJoin = 'miter';
    }
  };

  const floodFill = (x, y, targetColor, fillColor) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    const getPixel = (x, y) => {
      const index = (y * canvas.width + x) * 4;
      return {
        r: data[index],
        g: data[index + 1],
        b: data[index + 2],
        a: data[index + 3]
      };
    };

    const setPixel = (x, y, color) => {
      const index = (y * canvas.width + x) * 4;
      data[index] = color.r;
      data[index + 1] = color.g;
      data[index + 2] = color.b;
      data[index + 3] = color.a;
    };

    const matchColor = (color1, color2) => {
      return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a === color2.a;
    };

    const stack = [[x, y]];
    while (stack.length > 0) {
      const [x, y] = stack.pop();
      const currentColor = getPixel(x, y);

      if (matchColor(currentColor, targetColor) && !matchColor(currentColor, fillColor)) {
        setPixel(x, y, fillColor);

        if (x > 0) stack.push([x - 1, y]);
        if (x < canvas.width - 1) stack.push([x + 1, y]);
        if (y > 0) stack.push([x, y - 1]);
        if (y < canvas.height - 1) stack.push([x, y + 1]);
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const handleBucketFill = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = Math.floor(e.clientX - rect.left);
    const y = Math.floor(e.clientY - rect.top);
    
    const ctx = canvas.getContext('2d');
    const targetColor = ctx.getImageData(x, y, 1, 1).data;
    const fillColor = hexToRgb(color);

    floodFill(x, y, 
      {r: targetColor[0], g: targetColor[1], b: targetColor[2], a: targetColor[3]},
      {r: fillColor.r, g: fillColor.g, b: fillColor.b, a: 255}
    );
  };

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  const saveImage = () => {
    const link = document.createElement('a');
    link.download = 'frog-paint-image.png';
    link.href = canvasRef.current.toDataURL('image/png');
    link.click();
  };

  const colors = [
    '#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF',
    '#FFFF00', '#00FFFF', '#FF00FF', '#C0C0C0', '#808080',
    '#800000', '#808000', '#008000', '#800080', '#008080',
    '#000080', '#FFA500', '#A52A2A', '#FFC0CB', '#90EE90'
  ];

  const sizes = [1, 2, 3, 4];

  return (
    <div className="paint-app">
      <div className="toolbar">
        <button onClick={() => handleToolChange('pencil')} className={tool === 'pencil' ? 'active' : ''}>
          <img src="/pencil-icon.png" alt="Pencil" />
        </button>
        <button onClick={() => handleToolChange('eraser')} className={tool === 'eraser' ? 'active' : ''}>
          <img src="/eraser-icon.png" alt="Eraser" />
        </button>
        <button onClick={() => handleToolChange('bucket')} className={tool === 'bucket' ? 'active' : ''}>
          <img src="/bucket-icon.png" alt="Bucket" />
        </button>
        {(tool === 'pencil' || tool === 'eraser') && (
          <div className="size-options">
            {sizes.map((s) => (
              <button
                key={s}
                onClick={() => handleSizeChange(s)}
                className={`size-button ${size === s ? 'active' : ''}`}
                style={{ width: `${s * 5}px`, height: `${s * 5}px` }}
              />
            ))}
          </div>
        )}
        <div className="color-palette">
          {colors.map((c) => (
            <button
              key={c}
              style={{ backgroundColor: c }}
              onClick={() => handleColorChange(c)}
              className={color === c ? 'active' : ''}
            />
          ))}
        </div>
        <button className="save-button" onClick={saveImage}>Save As</button>
      </div>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onMouseOut={endDrawing}
      />
    </div>
  );
};

export default Paint;