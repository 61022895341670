import React, { useState, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
import './App.css';
import Story from './Story';
import Archives from './Archives';
import Hopspad from './Hopspad';
import HopsCmd from './HopsCmd';
import Travel from './Travel';
import Player from './Player';
import Paint from './Paint';
import WhackAFrog from './WhackAFrog';
import Explorer from './Explorer';
import Info from './Info';
import cursorImage from './cursor.png';
import restartIcon from './restart-icon.png';
import Raids from './Raids';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showStartMenu, setShowStartMenu] = useState(false);
  const [openWindows, setOpenWindows] = useState([]);
  const [minimizedWindows, setMinimizedWindows] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState([]);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [browser, setBrowser] = useState('');
  const [location, setLocation] = useState('');
  const [selectionBox, setSelectionBox] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedIcons, setSelectedIcons] = useState([]);
  const desktopRef = useRef(null);

  const [icons, setIcons] = useState([
    { id: 1, icon: "/story-icon.png", label: "Elon Musks Favorite Frog", content: "", type: "notepad", defaultSize: { width: 1200, height: 700 } },
    { id: 2, icon: "/travel-icon.png", label: "Frog Narrative", content: [], type: "folder", defaultSize: { width: 500, height: 600 } },
    { id: 3, icon: "/hopspad.png", label: "Office", content: "", type: "hopspad", defaultSize: { width: 1000, height: 600 } },
    { id: 4, icon: "/hopscmd.png", label: "Command Frog", content: "", type: "hopscmd", defaultSize: { width: 700, height: 700 } },
    { id: 6, icon: "/player.png", label: "MP3 Player", content: "", type: "player", defaultSize: { width: 350, height: 620 } },
    { id: 15, icon: "/nft.png", label: "Frog NFT", content: "", type: "link", url: "https://www.tensor.trade/trade/frog_nft" },
    { id: 7, icon: "/paint.png", label: "Paint", content: "", type: "paint", defaultSize: { width: 800, height: 600 } },
    { id: 8, icon: "/explorer.png", label: "Explorer", content: "", type: "explorer", defaultSize: { width: 800, height: 600 } },
    { id: 9, icon: "/chart.png", label: "Chart", content: "", type: "link", url: "https://www.binance.com/en/price/toadandfrog" },
    { id: 11, icon: "/whackafrog.png", label: "Whack-A-Frog", content: "", type: "whackafrog", defaultSize: { width: 400, height: 550 } },
    { id: 12, icon: "/swap.png", label: "Swap", content: "", type: "link", url: "https://raydium.io/swap/?inputMint=sol&outputMint=sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump" },
    { id: 13, icon: "/raids-icon.png", label: "Raid Templates", content: "", type: "raids", defaultSize: { width: 800, height: 600 } },
    { id: 14, icon: "/cmc.png", label: "CoinMarketCap", content: "", type: "link", url: "https://coinmarketcap.com/currencies/toadandfrog" },
    { id: 14, icon: "/coingeckoicon2.png", label: "CoinGecko", content: "", type: "link", url: "https://www.coingecko.com/en/coins/frog-4" }
]);

  const loadingSteps = [
    'Booting Frog OS...',
    'Establishing connection...',
    'Downloading app assets...',
    'Booting Solana...',
    'Booting Frog Shell...',
    'Booting Frog Explorer...',
    'Booting Frog Office...',
    'Booting Frog Paint...',
    'Booting MP3 Player...',
    'Booting Whack-A-Frog...',
    'Booting Explorer...',
    'Nuking jeets...',
    'Pumping the coin...',
    'Listing on Binance...'
  ];

  useEffect(() => {
    const totalDuration = 15000;
    const intervalDuration = totalDuration / loadingSteps.length;
    
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + (100 / loadingSteps.length);
        if (newProgress >= 100) {
          clearInterval(timer);
          setLoading(false);
          return 100;
        }
        return newProgress;
      });

      setLoadingMessages((oldMessages) => {
        const newMessage = loadingSteps[oldMessages.length];
        return [...oldMessages, newMessage];
      });
    }, intervalDuration);

    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Chrome") > -1) {
      setBrowser("Chrome");
    } else if (userAgent.indexOf("Safari") > -1) {
      setBrowser("Safari");
    } else if (userAgent.indexOf("Firefox") > -1) {
      setBrowser("Firefox");
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident/") > -1) {
      setBrowser("Internet Explorer");
    } else {
      setBrowser("Unknown");
    }

    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => setLocation(data.country_name))
      .catch(() => setLocation('Unknown'));

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const handleResize = () => {
      const taskbarHeight = 40;
      const screenHeight = window.innerHeight;
      const iconWidth = 80;
      const iconHeight = 90;
      const maxIconsVertical = Math.floor((screenHeight - taskbarHeight) / iconHeight);
      
      setIcons(prevIcons => {
        return prevIcons.map((icon, index) => {
          const column = Math.floor(index / maxIconsVertical);
          const row = index % maxIconsVertical;
          return {
            ...icon,
            position: {
              x: column * iconWidth,
              y: row * iconHeight
            }
          };
        });
      });

      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      clearInterval(timer);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openWindow = (icon) => {
    if (icon.type === 'link') {
      window.open(icon.url, '_blank');
    } else {
      setOpenWindows([...openWindows, { ...icon, minimized: false, maximized: false }]);
    }
  };

  const closeWindow = (id) => {
    setOpenWindows(openWindows.filter(window => window.id !== id));
    setMinimizedWindows(minimizedWindows.filter(window => window.id !== id));
  };

  const minimizeWindow = (window) => {
    setOpenWindows(openWindows.filter(w => w.id !== window.id));
    setMinimizedWindows([...minimizedWindows, window]);
  };

  const maximizeWindow = (id) => {
    setOpenWindows(openWindows.map(window => 
      window.id === id ? { ...window, maximized: !window.maximized } : window
    ));
  };

  const restoreWindow = (window) => {
    setMinimizedWindows(minimizedWindows.filter(w => w.id !== window.id));
    setOpenWindows([...openWindows, { ...window, minimized: false }]);
  };

  const restartOS = () => {
    setLoading(true);
    setProgress(0);
    setLoadingMessages([]);
    setShowStartMenu(false);
    setOpenWindows([]);
    setMinimizedWindows([]);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleMouseDown = (e) => {
    if (e.target.className === 'desktop' || e.target.className === 'desktop-icons') {
      const rect = desktopRef.current.getBoundingClientRect();
      const startX = e.clientX - rect.left;
      const startY = e.clientY - rect.top;
      setSelectionBox({ startX, startY, endX: startX, endY: startY });
      setSelectedIcons([]);
    }
  };

  const handleMouseMove = (e) => {
    if (selectionBox) {
      const rect = desktopRef.current.getBoundingClientRect();
      const endX = e.clientX - rect.left;
      const endY = e.clientY - rect.top;
      setSelectionBox({ ...selectionBox, endX, endY });

      const selectedIconIds = icons.filter(icon => {
        const iconRect = {
          left: icon.position.x,
          top: icon.position.y,
          right: icon.position.x + 80,
          bottom: icon.position.y + 90
        };
        return (
          Math.min(selectionBox.startX, endX) < iconRect.right &&
          Math.max(selectionBox.startX, endX) > iconRect.left &&
          Math.min(selectionBox.startY, endY) < iconRect.bottom &&
          Math.max(selectionBox.startY, endY) > iconRect.top
        );
      }).map(icon => icon.id);

      setSelectedIcons(selectedIconIds);
    }
  };

  const handleMouseUp = () => {
    setSelectionBox(null);
  };

  const DesktopIcon = ({ icon, label, onOpen, position, isSelected }) => (
    <div 
      className={`desktop-icon ${isSelected ? 'selected' : ''}`}
      onDoubleClick={onOpen}
      onTouchEnd={onOpen}
      style={{
        position: 'absolute',
        left: position.x,
        top: position.y
      }}
    >
      <img src={icon} alt={label} />
      <span>{label}</span>
    </div>
  );

  const Window = ({ id, title, icon, children, onClose, onMinimize, onMaximize, type, isMaximized, defaultSize }) => (
    <Rnd
      default={{ 
        x: 150, 
        y: 150, 
        width: defaultSize.width, 
        height: defaultSize.height 
      }}
      minWidth={200}
      minHeight={120}
      disableDragging={isMaximized || isMobile}
      enableResizing={!isMaximized && !isMobile}
      size={isMaximized || isMobile ? { width: '100%', height: 'calc(100vh - 40px)' } : undefined}
      position={isMaximized || isMobile ? { x: 0, y: 0 } : undefined}
      bounds="window"
    >
      <div className={`window ${isMaximized ? 'maximized' : ''}`}>
        <div className="window-header">
          <img src={icon} alt={title} className="window-icon" />
          <span className="window-title">{title}</span>
          <div className="window-controls">
            <button 
              className="window-control-button minimize" 
              onClick={onMinimize}
              onTouchEnd={(e) => { e.preventDefault(); onMinimize(); }}
            >
              &#8212;
            </button>
            <button 
              className="window-control-button maximize" 
              onClick={onMaximize}
              onTouchEnd={(e) => { e.preventDefault(); onMaximize(); }}
            >
              {isMaximized ? '\u25A2' : '\u25A1'}
            </button>
            <button 
              className="window-control-button close" 
              onClick={onClose}
              onTouchEnd={(e) => { e.preventDefault(); onClose(); }}
            >
              &times;
            </button>
          </div>
        </div>
        <div className="window-content">
          {type === 'notepad' && <Story />}
          {type === 'folder' && <Archives />}
          {type === 'hopspad' && <Hopspad />}
          {type === 'hopscmd' && <HopsCmd />}
          {type === 'travel' && <Travel />}
          {type === 'player' && <Player />}
          {type === 'paint' && <Paint />}
          {type === 'whackafrog' && <WhackAFrog />}
          {type === 'explorer' && <Explorer />}
          {type === 'raids' && <Raids />}
          {type === 'info' && <Info />}
          {type === 'swap' && (
            <iframe
              src="https://raydium.io/swap/?inputMint=sol&outputMint=sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump"
              style={{ width: '100%', height: '100%', border: 'none' }}
              title="Swap"
            />
          )}
        </div>
      </div>
    </Rnd>
  );

  const StartMenu = () => (
    <div className="start-menu">
      <div className="start-menu-column">
        <h3>Links</h3>
        <ul>
          <li><img src="/dexscreener-icon.png" alt="DexScreener" /><a href="https://dexscreener.com/solana/sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump" target="_blank" rel="noopener noreferrer">DexScreener</a></li>
          <li><img src="/dextools-icon.png" alt="DexTools" /><a href="https://www.dextools.io/app/en/solana/pair-explorer/sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump" target="_blank" rel="noopener noreferrer">DexTools</a></li>
          <li><img src="/telegram-icon.png" alt="Telegram" /><a href="https://t.me/FrogByLobel" target="_blank" rel="noopener noreferrer">Telegram</a></li>
          <li><img src="/twitter-icon.png" alt="Twitter" /><a href="https://twitter.com/FrogAndToadCoin" target="_blank" rel="noopener noreferrer">Twitter</a></li>
        </ul>
        <h3>System</h3>
        <ul>
          <li onClick={restartOS}><img src={restartIcon} alt="Restart OS" /><span>Restart OS</span></li>
        </ul>
      </div>
      <div className="start-menu-column">
        <h3>Apps</h3>
        <ul>
        {icons.filter(icon => ['hopspad', 'hopscmd', 'player', 'paint', 'whackafrog', 'explorer'].includes(icon.type)).map(icon => (
            <li key={icon.id} onClick={() => { openWindow(icon); setShowStartMenu(false); }}>
              <img src={icon.icon} alt={icon.label} />
              <span>{icon.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="loading-screen">
        <video autoPlay muted loop className="background-video">
          <source src="/loadinghops.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="loading-cmd">
          <div className="cmd-header">
            <span>Frog OS Boot Sequence</span>
            <div className="cmd-controls">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="cmd-content">
            {loadingMessages.map((message, index) => (
              <div key={index} className="cmd-line">
                <span className="cmd-prompt">$</span>
                <span className="cmd-text">{message}</span>
              </div>
            ))}
          </div>
          <div className="cmd-progress">
            <span>Loading: {Math.round(progress)}%</span>
            <div className="progress-bar">
              <div className="progress-fill" style={{width: `${progress}%`}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div 
      className="desktop" 
      style={{ backgroundImage: "url('/hopsbackground.png')", cursor: `url(${cursorImage}), auto` }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleMouseUp}
      ref={desktopRef}
    >
      <div className="desktop-icons">
        {icons.map((icon) => (
          <DesktopIcon 
            key={icon.id}
            icon={icon.icon}
            label={icon.label}
            onOpen={() => openWindow(icon)}
            position={icon.position}
            isSelected={selectedIcons.includes(icon.id)}
          />
        ))}
      </div>
      
      <div className="taskbar">
        <button 
          className="start-button" 
          onClick={() => setShowStartMenu(!showStartMenu)}
          onTouchEnd={(e) => { e.preventDefault(); setShowStartMenu(!showStartMenu); }}
        >
          Start
        </button>
        {showStartMenu && <StartMenu />}
        <div className="taskbar-items">
          {minimizedWindows.map((window) => (
            <button 
              key={window.id} 
              className="taskbar-item" 
              onClick={() => restoreWindow(window)}
              onTouchEnd={(e) => { e.preventDefault(); restoreWindow(window); }}
            >
              <img src={window.icon} alt={window.label} className="taskbar-icon" />
              <span>{window.label}</span>
            </button>
          ))}
        </div>
        <div className="status-bar">
          <div className="status-item">
            <span className="status-label">Status:</span>
            <span className={`status-value ${isOnline ? 'online' : 'offline'}`}>
              {isOnline ? 'Connected' : 'Disconnected'}
            </span>
          </div>
          <div className="status-item">
            <span className="status-label">Browser:</span>
            <span className="status-value">{browser}</span>
          </div>
          <div className="status-item">
            <span className="status-label">Location:</span>
            <span className="status-value">{location}</span>
          </div>
        </div>
      </div>

      {openWindows.map((window) => (
        <Window
          key={window.id}
          id={window.id}
          title={window.label}
          icon={window.icon}
          onClose={() => closeWindow(window.id)}
          onMinimize={() => minimizeWindow(window)}
          onMaximize={() => maximizeWindow(window.id)}
          type={window.type}
          isMaximized={window.maximized || isMobile}
          defaultSize={window.defaultSize}
        >
          {window.content}
        </Window>
      ))}

      {selectionBox && (
        <div
          style={{
            position: 'absolute',
            left: Math.min(selectionBox.startX, selectionBox.endX),
            top: Math.min(selectionBox.startY, selectionBox.endY),
            width: Math.abs(selectionBox.endX - selectionBox.startX),
            height: Math.abs(selectionBox.endY - selectionBox.startY),
            border: '1px solid #00FF00',
            backgroundColor: 'rgba(0, 255, 0, 0.1)',
            pointerEvents: 'none'
          }}
        />
      )}
    </div>
  );
};

export default App;