import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import './Hopspad.css';

const Hopspad = () => {
  const [fontSize, setFontSize] = useState(16);
  const [fileType, setFileType] = useState('txt');
  const contentEditableRef = useRef(null);

  useEffect(() => {
    if (contentEditableRef.current) {
      contentEditableRef.current.innerHTML = '<span class="placeholder">Start typing...</span>';
    }
  }, []);

  const handleFontSizeChange = (e) => {
    setFontSize(Number(e.target.value));
  };

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const parseHTMLToDocx = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const paragraphs = [];

    doc.body.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.trim()) {
          paragraphs.push(new Paragraph({
            children: [new TextRun(node.textContent.trim())]
          }));
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const textRuns = [];
        node.childNodes.forEach((childNode) => {
          if (childNode.nodeType === Node.TEXT_NODE) {
            textRuns.push(new TextRun(childNode.textContent));
          } else if (childNode.nodeType === Node.ELEMENT_NODE) {
            const text = childNode.textContent;
            const isBold = childNode.tagName === 'STRONG' || childNode.style.fontWeight === 'bold';
            const isItalic = childNode.tagName === 'EM' || childNode.style.fontStyle === 'italic';
            const isUnderline = childNode.tagName === 'U' || childNode.style.textDecoration === 'underline';
            textRuns.push(new TextRun({
              text,
              bold: isBold,
              italic: isItalic,
              underline: isUnderline,
            }));
          }
        });
        paragraphs.push(new Paragraph({ children: textRuns }));
      }
    });

    return paragraphs;
  };

  const handleSave = async () => {
    let content = contentEditableRef.current.innerHTML;
    let blob;
    let filename;

    if (fileType === 'txt') {
      content = contentEditableRef.current.innerText;
      blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
      filename = 'frogpad_note.txt';
    } else {
      const doc = new Document({
        sections: [{
          properties: {},
          children: parseHTMLToDocx(content),
        }],
      });

      blob = await Packer.toBlob(doc);
      filename = 'frogpad_note.docx';
    }

    saveAs(blob, filename);
  };

  const applyStyle = (style) => {
    document.execCommand(style, false, null);
    contentEditableRef.current.focus();
  };

  const handleContentFocus = () => {
    if (contentEditableRef.current.innerHTML === '<span class="placeholder">Start typing...</span>') {
      contentEditableRef.current.innerHTML = '';
    }
  };

  const handleContentBlur = () => {
    if (contentEditableRef.current.innerHTML === '') {
      contentEditableRef.current.innerHTML = '<span class="placeholder">Start typing...</span>';
    }
  };

  return (
    <div className="hopspad-container">
      <div className="hopspad-header">
        Frogpad
      </div>
      <div className="hopspad-controls">
        <div className="style-buttons">
          <button onClick={() => applyStyle('bold')} className="style-button">B</button>
          <button onClick={() => applyStyle('italic')} className="style-button">I</button>
          <button onClick={() => applyStyle('underline')} className="style-button">U</button>
        </div>
        <label className="font-size-label">
          Font Size:
          <input
            type="number"
            value={fontSize}
            onChange={handleFontSizeChange}
            min="8"
            max="72"
          />
        </label>
        <div className="right-controls">
          <select value={fileType} onChange={handleFileTypeChange} className="file-type-select">
            <option value="txt">TXT</option>
            <option value="docx">DOCX</option>
          </select>
          <button onClick={handleSave} className="save-button">Save As</button>
        </div>
      </div>
      <div
        ref={contentEditableRef}
        contentEditable
        className="hopspad-content"
        style={{ fontSize: `${fontSize}px` }}
        onFocus={handleContentFocus}
        onBlur={handleContentBlur}
      />
    </div>
  );
};

export default Hopspad;