import React, { useState } from 'react';
import './Archives.css';

const Archives = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { id: 1, src: "/image1.jpg", name: "image1.jpg" },
    { id: 2, src: "/image2.jpg", name: "image2.jpg" },
    { id: 3, src: "/image3.jpg", name: "image3.jpg" }
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleBack = () => {
    setSelectedImage(null);
  };

  const handleDownload = (imageSrc, imageName) => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="archives-container">
      <div className="explorer-bar">
        C:/Frog OS/Frog Archive.zip
      </div>
      {selectedImage ? (
        <div className="image-viewer">
          <button onClick={handleBack} className="back-button">Back</button>
          <a 
            href="#" 
            onClick={(e) => {
              e.preventDefault();
              handleDownload(selectedImage.src, selectedImage.name);
            }} 
            className="download-link"
          >
            Download
          </a>
          <img src={selectedImage.src} alt={selectedImage.name} />
        </div>
      ) : (
        <>
          <p className="archives-intro">
            "Frog and Toad" is a beloved series of children's books written and illustrated by Arnold Lobel in 1971. 
            The books follow the adventures of two amphibian friends: the optimistic Frog and the grumpy but lovable Toad. 
            It's one of Elon Musk's favorite books, and it's also older than Pepe which came in the 2000s. Frog is not Pepe. Learn more at{' '}
            <a 
              href="https://en.wikipedia.org/wiki/Frog_and_Toad" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="website-link"
            >
              <span className="link-emoji">🔗</span>
              Wikipedia
            </a>
          </p>
          <div className="image-grid">
            {images.map((image) => (
              <div key={image.id} className="image-item" onClick={() => handleImageClick(image)}>
                <img src={image.src} alt={image.name} />
                <span>{image.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Archives;