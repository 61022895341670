import React from 'react';
import './Info.css';

const Info = () => {
  return (
    <div className="info-container">
      <div className="info-content">
        <h1 className="info-headline">How To Buy Hops</h1>
        <p className="info-text">Buying $HOPS tokens is as easy as 1, 2, 3.</p>
        <p className="info-text">Make a wallet, deposit SOL, and swap for HOPS.</p>
        <div className="image-container">
          <img src="/newyork.png" alt="New York" className="info-image" />
        </div>
        <h2 className="info-subheadline">Here's how to do it:</h2>
        
        <h3 className="info-step">Step 1) Create Wallet</h3>
        <p className="info-text">
          <a href="https://phantom.app/" target="_blank" rel="noopener noreferrer"><span className="link-emoji">🔗</span>Head to Phanton.app</a>, install the Phantom extension.
        </p>
        
        <h3 className="info-step">Step 2) Purchase SOL</h3>
        <p className="info-text">
          If you have crypto, use <a href="https://changenow.io" target="_blank" rel="noopener noreferrer"><span className="link-emoji">🔗</span>ChangeNow.io</a> to swap your current tokens for Solana.
        </p>
        <p className="info-text">
          If you don't have crypto and want to buy with a credit card, safe sites affiliates like <a href="https://www.moonpay.com/" target="_blank" rel="noopener noreferrer"><span className="link-emoji">🔗</span>MoonPay</a> can get you SOL.
        </p>
        
        <h3 className="info-step">Step 3) Swap SOL for HOPS</h3>
        <p className="info-text">Press the "Swap" icon on the Hops OS here and the contract is automatically loaded.</p>
        <p className="info-text">CA: DH55ZkeC31wviZDggSmd42cH1dKMQQiR7Rzqr2iopump</p>
        <p className="info-text">Connect Phantom, and input the amount of SOL you want to swap for HOPS. Swap, and done.</p>
        
        <div className="button-container">
          <a href="https://pump.fun/DH55ZkeC31wviZDggSmd42cH1dKMQQiR7Rzqr2iopump" target="_blank" rel="noopener noreferrer" className="buy-button">
          🛒Buy Hops
          </a>
        </div>
      </div>
    </div>
  );
};

export default Info;