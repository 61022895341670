import React from 'react';
import './Story.css';

const Story = () => {
  // Helper function to add link emoji to links
  const addLinkEmoji = (element) => {
    if (React.isValidElement(element) && element.type === 'a') {
      return React.cloneElement(element, {}, 
        <><span className="link-emoji">🔗</span>{element.props.children}</>
      );
    }
    if (React.isValidElement(element) && element.props.children) {
      const newChildren = React.Children.map(element.props.children, child => 
        typeof child === 'string' ? child : addLinkEmoji(child)
      );
      return React.cloneElement(element, {}, newChildren);
    }
    return element;
  };

  const content = [
    <p key="1"><strong>1.</strong> Elon Musk followed a frog called "Toad" on Twitter. It turned out to be a scam coin, but the CTO version was successful.</p>,
    <img src="/elon_tweet.png" alt="Elon Musk Tweet" key="elon" />,
    <p key="2"><strong>2.</strong> The Toad was from the series "Frog and Toad" by Arnold Lobel from 1971. It was the first meme book and the first cartoon frog ever.</p>,
    <img src="/frogbook.png" alt="Frog and Toad Book" key="book" />,
    <p key="3"><strong>3.</strong> This is that Frog on blockchain</p>,
    <div className="video-container" key="4" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <iframe 
        src="https://www.youtube.com/embed/FpqieOCHgpo"
        title="Frog on Blockchain"
        allowFullScreen
        style={{ maxWidth: '800px', width: '100%', aspectRatio: '16/9' }}
      />
    </div>
  ];

  const buttons = [
    { text: "Buy Frog", link: "https://raydium.io/swap/?inputMint=sol&outputMint=sm6LoSiRQLM28rMgcd8GtR5XBkZFMEYXgEUgwCxpump" },
    { text: "Follow Frog", link: "https://twitter.com/FrogAndToadCoin" }
  ];

  return (
    <div className="story-container">
      <div className="explorer-bar">
        C:/Frog OS/Story.pdf
      </div>
      <div className="content-wrapper">
        <div className="story-content">
          <h1>The Story of Frog</h1>
          {content.map(addLinkEmoji)}
          <div className="button-container">
            {buttons.map((button, index) => (
              <a key={index} href={button.link} target="_blank" rel="noopener noreferrer" className="button">
                <span className="link-emoji">🔗</span>{button.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;